import React, {useState} from 'react'; 
import { useWeb3React } from '@web3-react/core';
import { Modal, Form } from 'react-bootstrap';
import { Contract, ethers } from 'ethers';
import config from '../../../config';
import abi from '../../abis/MDLDAO.abi'; 
import { useSWRConfig } from 'swr';

const CreateProposal = (props) => {

    const {account, active, library} = useWeb3React(); 
    const [title, setTitle] = useState(); 
    const [description, setDescription] = useState(); 
    const [time, setTime] = useState(0); //Default time is 24 hours | 1 day 

    const {cache, mutate} = useSWRConfig(); 

    console.log("KEYS");
    console.log(cache.keys()); 

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }
    const onChangeDesc = (e) => {
        setDescription(e.target.value);
    }
    const onChangeTime = (e) => {
        setTime(parseInt(e.target.value)); 
    }


    const submit = async(e) => {
        e.preventDefault(); 

        if(!title){window.alert("Please set a title"); return;};  
        if(!description){window.alert("Please set a description"); return; }; 
        if(!(active && account && library)) window.alert("Wallet is not connected!");

        let daoContract = new Contract(config.contract_address, abi, library.getSigner()); 
        let tx = await daoContract.createProposal(title, description, time);
        let receipt = await tx.wait(); 
        mutate([config.contract_address, 'proposal_index']); 
        
    } 

    return (
        <Modal
            dialogClassName='create-proposal-dialog'
            aria-labelledby="create-proposal"
            centered
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title id="create-proposal">
                    Create A Proposal
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={submit}>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control style={{color: 'white'}} placeholder="Enter a title" as="textarea" onChange={onChangeTitle}/>
                        {/* <Form.Text id="proposal-title" style={{color: 'white'}}></Form.Text> */}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control placeholder="Enter your proposal" as="textarea" rows={6} style={{color: 'white'}} onChange={onChangeDesc}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        {/* Set proposal length type */}
                        <Form.Label>Time Length</Form.Label>
                        <Form.Control as="select" style={{color: 'white'}} onChange={onChangeTime} value={time}>
                            <option value={0}>1 Day</option>
                            <option value={1}>3 Days</option>
                            <option value={2}>7 Days</option>
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="btn btn-fw btn-success">
                        Create
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    )   
}

export default CreateProposal; 