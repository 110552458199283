import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import React, { useEffect, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { RegisteredContext } from '../../../registeredContext';
import useSWR from 'swr'; 
import config from '../../../config';
import abi from '../../abis/MDLDAO.abi';
import { Contract } from 'ethers';


const Wallet = (props) => {

    const injectedConnector = new InjectedConnector({supportedChainIds: [1, 4]});
    const {chainId, account, activate, active, library, deactivate} = useWeb3React(); 

    const {data: daoVoter, mutate} = useSWR([config.contract_address, 'registeredVoters', account]);
    console.log("TEST VOTER");
    console.log(daoVoter)

    const register = async() => {
      let daoContract = new Contract(config.contract_address, abi, library.getSigner()); 
      try{
        let tx = await daoContract.register(config.verifiable_assets.digidynos);
        let receipt = await tx.wait(); 
        mutate([config.contract_address, 'registeredVoters', account]);
      }
      catch(e){
        window.alert(e);
      }
       
    } 

    const connect = () => {
        console.log("CONNECT")
        activate(injectedConnector);
        localStorage.setItem("walletConnected", true); 
    }

    const disconnect = () => {
        console.log("DISCONNECT");
        deactivate(injectedConnector); 
        localStorage.setItem('walletConnected', false); 
    }

    useEffect(() => {
        console.log(chainId, account, active)
    })

    return (
        <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <i className="mdi mdi-wallet"></i>
                  <p className="mb-0 d-none d-sm-block navbar-profile-name">
                    {active ? (
                        <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{account}</span>
                    ):
                    (
                        <Trans>Not Connected</Trans>
                    )
                      
                    }
                      
                      </p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <Dropdown.Divider />

                {
                  (!(daoVoter ? (daoVoter.registered) : (true)) && active) ? 
                    (<Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-dark rounded-circle">
                          <i className="mdi mdi-login text-success"/>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <p className="preview-subject mb-1">
                          <button onClick={register} style={{backgroundColor: "transparent", padding: 5}} className="btn-outline-success">Register</button>
                        </p>
                      </div>
                    </Dropdown.Item>)
                    :  
                    (
                      null
                    )
                }
                <Dropdown.Divider />

                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                        {
                            active ? (
                                <i className="mdi mdi-logout text-danger"/>
                            ) : (
                                <i className="mdi mdi-login text-success"/>
                            )

                        }
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                        {
                            active ? (
                                <button onClick={disconnect} style={{backgroundColor: "transparent", padding: 5}} className="btn-outline-danger">Disconnect</button> 
                            ) : (
                                <button onClick={connect} style={{backgroundColor: "transparent", padding: 5}} className="btn-outline-success">Connect</button>
                            )
                        }
                    </p>
                  </div>
                </Dropdown.Item>    
              </Dropdown.Menu>
            </Dropdown>
    )
}

export default Wallet; 