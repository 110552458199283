import { useWeb3React } from '@web3-react/core';
import React from 'react'; 
import useSWR from 'swr';
import {fetcher} from '../../../utils';

const WalletBalance = (props) => {
    const {account, library} = useWeb3React(); 

    const {data: balance} = useSWR(['getBalance', account], {
        fetcher: fetcher(library), 
    })
    console.log("Balance: ", balance ); 

    return (
        <span>
            {balance ? (balance/1e18).toFixed(3) : (0)} ETH
        </span>
    )
}

export default WalletBalance; 