import React, { Component, useState, useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import config from '../../config';
import useSWR from 'swr';
import { useWeb3React } from '@web3-react/core';

//Components
import Notifications from './NavComponents/Notifications';
import Wallet from './NavComponents/Wallet';
import WalletBalance from './NavComponents/WalletBalance';
import CreateProposal from '../dashboard/Modals/CreateProposal';



const CreateProposalButton = (props) => {
  const {account, active, library} = useWeb3React(); 
  const [show, setShow] = useState(false); 
  const {data: daoVoter} = useSWR([config.contract_address, 'registeredVoters', account]);

  return (
    <>
      <button style={{paddingLeft: 10, paddingRight: 10}} className="nav-link btn btn-success create-new-button no-caret" onClick={()=>{setShow(true)}} disabled={!(daoVoter && daoVoter.registered)}>
        + Create A Proposal
      </button>
      <CreateProposal show={show} onHide={()=>{setShow(false)}}/>
    </>
  )
}


class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render () {
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
          {/* <ul className="navbar-nav w-100">
            <li className="nav-item w-100">
              <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input type="text" className="form-control" placeholder="Search products" />
              </form>
            </li>
          </ul> */}
          <ul className="navbar-nav navbar-nav-right">
           <CreateProposalButton />
            
            <li className="nav-item d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
                <i className="mdi mdi-view-grid"></i>
              </a>
            </li>
            {/* <Notifications /> */}
            <WalletBalance />
            <Wallet />
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
