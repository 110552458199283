import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './app/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import {InjectedConnector} from '@web3-react/injected-connector'
import abi from './app/abis/MDLDAO.abi';
import { SWRConfig, useSWRConfig } from 'swr';
import {fetcher} from './utils';
import { RegisteredContextProvider } from './registeredContext';


const ABIs = [
  ["0x9d0d4572cD1dD2A864763c5BFf238e4d040812cD", abi]
];

function getLibrary(provider){
  const library = new Web3Provider(provider)
  library.pollingInterval = 2000
  return library
}

const SWRConfigWrapper = ({children}) => {
  const injectedConnector = new InjectedConnector({supportedChainIds: [1]});
  const {activate, library, active} = useWeb3React();  
  useEffect(() => {
    const connectWalletOnLoad = async () => {
      if(localStorage?.getItem('walletConnected') === "true"){
        try {
          await activate(injectedConnector);
        }
        catch(e){
          window.alert(e)
        }
      }
      else{
        // window.alert("walletConnected = false")
      }
    }
    connectWalletOnLoad(); 
  }, [localStorage.getItem('walletConnected')])
  return (
    <>
    {
      active ? (
        <SWRConfig value={{fetcher: fetcher(library, abi)}}>
        {children}
      </SWRConfig>
      )
      : 
        (children)
    }
      
    </>
  
  )
}




ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <SWRConfigWrapper>
      <RegisteredContextProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </RegisteredContextProvider>
    </SWRConfigWrapper>
  </Web3ReactProvider>
, document.getElementById('root'));

serviceWorker.unregister();