import { useWeb3React } from '@web3-react/core';
import React from 'react'; 
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { InjectedConnector } from '@web3-react/injected-connector';
const SidebarWallet = (props) => {

    const injectedConnector = new InjectedConnector({supportedChainIds: [1, 4]});
    const {chainId, account, activate, active, library, deactivate} = useWeb3React(); 

    const connect = () => {
      console.log("CONNECT");
      activate(injectedConnector);
    }
    const disconnect = () => {
      console.log("DISCONNECT");
      deactivate(injectedConnector); 
    }

    return(
        <li className="nav-item profile">
        <div className="profile-desc">
          <div className="profile-pic">
            <div className="count-indicator">
              <i className="mdi mdi-wallet"></i>
            </div>
            <div className="profile-name">
              <h5 className="mb-0 font-weight-normal" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: 'white', width: "100px"}}>
                {
                  active ? (
                    <span style={{color: 'white'}}>{account}</span>
                  ) : (
                    <span style={{color: "white"}}>Not Connected</span>
                  )
                }
              </h5>
            </div>
          </div>
          <Dropdown alignRight>
            <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
              <i className="mdi mdi-dots-vertical"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                        {
                            active ? (
                                <i className="mdi mdi-logout text-danger"/>
                            ) : (
                                <i className="mdi mdi-login text-success"/>
                            )

                        }
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                        {
                            active ? (
                                <button onClick={disconnect} style={{backgroundColor: "transparent", padding: 5}} className="btn-outline-danger">Disconnect</button> 
                            ) : (
                                <button onClick={connect} style={{backgroundColor: "transparent", padding: 5}} className="btn-outline-success">Connect</button>
                            )
                        }
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                
              </Dropdown.Menu>
          </Dropdown>
        </div>
      </li>
    )
}

export default SidebarWallet; 