import React, { createContext, useState } from "react";

import config from "./config";
import { useWeb3React } from "@web3-react/core";
import useSWR from 'swr'; 

const RegisteredContext = createContext({}); 

const RegisteredContextProvider = ({children}) => {
    const {account, active, library } = useWeb3React(); 
    const {data: daoRegisterStatus, mutate} = useSWR([config.contract_address, 'registeredVoters', account]); 

    console.log("REGISTER VOTER")
    console.log(daoRegisterStatus);

    const register = async() => {
        if(!(account && active && library)) {window.alert('Wallet is not connected!'); return;};
        mutate(true, false); 
        // window.alert("Trying to register"); 
    }

    return(
        <RegisteredContext.Provider value={{daoRegisterStatus, register}}>
            {children}
        </RegisteredContext.Provider>
    )
}

export {RegisteredContextProvider, RegisteredContext}