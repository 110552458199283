import React, {useEffect, useState} from 'react'; 
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { isAddress } from "ethers/lib/utils";
import useSWR from "swr";


//SWR Fetcher
const fetcher = (library, abi) => (...args) => {
    const [arg1, arg2, ...params] = args; 

    if(isAddress(arg1)){
        if(!library){
            console.log("library not defined"); 
            return; 
        }
        const address = arg1; 
        const method = arg2; 
        const contract = new Contract(address, abi, library.getSigner()); 
        return contract[method](...params); 
    }

    const method = arg1; 
    return library[method](arg2, ...params); 
} 




export {
    fetcher
}